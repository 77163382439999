import React from 'react';
import styles from './Numbers.module.scss';

const Unit = ({ unit }) => (
  <span
    className={styles.unit}
    dangerouslySetInnerHTML={{ __html: `${unit}` }}
  />
);

const Number = ({ value, description, unit, prefix }) => {
  return (
    <div className={styles.item}>
      <div className={styles.number}>
        <div className={styles.numberContent}>
          {prefix && <span className={styles.unit}>{prefix}</span>}
          {value}
          {unit && <Unit unit={unit} />}
        </div>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default function Numbers({ items }) {
  return (
    <div className={styles.container}>
      {items.map(({ node: { value, description, unit, prefix } }, id) => (
        <div className={styles.numberBox} key={id}>
          <Number
            value={value}
            description={description}
            unit={unit}
            prefix={prefix}
          />
        </div>
      ))}
    </div>
  );
}
