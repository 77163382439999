import React from 'react';
import { object, arrayOf, string } from 'prop-types';

import Image from 'src/components/Image';
import styles from './ListBox.module.scss';

export default function ListBox({ items, image, imageAlt }) {
  return (
    <div className={styles.listBox}>
      <div className={styles.imageContainer}>
        <Image alt={imageAlt} filename={image} />
      </div>
      <div className={styles.listContainer}>
        <ul className={styles.list}>
          {items.map((item, id) => (
            <li key={id} className={styles.listItem}>
              {item.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

ListBox.propTypes = {
  items: arrayOf(object),
  image: string,
};
