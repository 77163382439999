import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './SliderOverwrites.scss';

const Slider = ({ children, infiniteLoop }) => (
  <Carousel
    showThumbs={false}
    showStatus={false}
    showIndicators={false}
    infiniteLoop={infiniteLoop}
    interval={10000}
    swipeScrollTolerance={80}
    transitionTime={500}
    stopOnHover
    autoPlay
  >
    {children}
  </Carousel>
);

export default Slider;
