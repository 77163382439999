import React from 'react';
import { string } from 'prop-types';

import styles from './HeroText.module.scss';

export default function HeroText({ text }) {
  return (
    <div className={styles.heroText}>
      <p>{text}</p>
    </div>
  );
}

HeroText.propTypes = {
  text: string,
};
