import React from 'react';
import { node, string } from 'prop-types';

import styles from './Section.module.scss';

export default function Section({ children, title }) {
  return (
    <div className={styles.section}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.content}>{children}</div>
    </div>
  );
}

Section.propTypes = {
  children: node,
  title: string,
};
