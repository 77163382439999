import React from 'react';
import styles from '../Slide.module.scss';

export default function Slide({
  slide: {
    node: {
      title,
      url,
      content,
      image: {
        asset: {
          fluid: { src },
        },
      },
      ribbon,
    },
  },
}) {
  return (
    <div className={styles.container}>
      <div className={styles.image} style={{ backgroundImage: `url(${src})` }}>
        <a className={styles.link} href={url} target="blank">
          {''}
        </a>
      </div>
      {ribbon && (
        <img src={ribbon.asset.fluid.src} alt="" className={styles.badge} />
      )}
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          {title && (
            <h2 className={styles.title}>
              <a href={url} target="blank">
                {title}
              </a>
            </h2>
          )}
          <p className={styles.text}>{content}</p>
        </div>
      </div>
    </div>
  );
}
