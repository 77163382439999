import React from 'react';
import styles from './Company.module.scss';

const Company = ({
  node: {
    title,
    name,
    buttonLabel,
    image: {
      asset: {
        fluid: { src = '' },
      },
    },
    url,
    primaryColor: { hex = '' },
  },
}) => {
  const linkProps = {
    className: styles.link,
    ...(url && {
      href: url,
    }),
  };

  return (
    <div className={styles.tile}>
      <a className={styles.link} {...linkProps}>
        <div
          className={styles.header}
          style={{
            backgroundImage: `url(${src})`,
          }}
        >
          <span className={styles.subtitle} style={{ borderColor: hex }}>
            {name}
          </span>
        </div>
        <div className={styles.content}>
          <div>
            <h2
              dangerouslySetInnerHTML={{ __html: title }}
              className={styles.title}
            />
          </div>
          <div className={styles.button} style={{ backgroundColor: hex }}>
            {buttonLabel}
          </div>
        </div>
      </a>
    </div>
  );
};

export default Company;
