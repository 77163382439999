import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'src/components/Layout';
import Seo from 'src/components/Seo';
import Section from 'src/components/Section';
import Company from 'src/components/Company';
import Numbers from 'src/components/Numbers';
import ListBox from 'src/components/ListBox';
import Image from 'src/components/Image';
import HeroText from 'src/components/HeroText';
import { Slider, Slide } from 'src/components/Slider';

import {
  useCompaniesData,
  useSlidesData,
  useAllconNumbersData,
  useAllconDescriptionData,
} from 'src/hooks';
import { localize } from 'src/utils';

import { getDataForLang } from 'src/data';
import CONSTANTS from 'src/data/constants';
import translate from 'src/translations';

import styles from './HomePage.module.scss';

const HomePage = ({ lang }) => {
  const certificates = getDataForLang('certificates', lang);
  const awards = getDataForLang('awards', lang);

  const companies = localize(useCompaniesData(), [lang]);
  const slides = localize(useSlidesData(), [lang]);
  const allconNumbers = localize(useAllconNumbersData(), [lang]);
  const [allconDescription] = localize(useAllconDescriptionData(), [lang]);

  return (
    <Layout>
      <Seo title={CONSTANTS.META_TITLE} />
      {companies.length ? (
        <div className={styles.tilesContainer}>
          {companies.map((company, id) => (
            <Company key={id} {...company} />
          ))}
        </div>
      ) : null}
      {slides.length ? (
        <div className={styles.sliderContainer}>
          {slides.length > 1 ? (
            <Slider infiniteLoop>
              {slides.map((item, id) => (
                <Slide slide={item} key={id} />
              ))}
            </Slider>
          ) : (
            <Slide slide={slides[0]} />
          )}
        </div>
      ) : null}
      {allconNumbers && (
        <div id="section-numbers">
          <Section title={translate('aboutAllcon')}>
            <Numbers items={allconNumbers} />
          </Section>
        </div>
      )}
      {allconDescription && (
        <Section>
          <HeroText text={allconDescription.node.description} />
        </Section>
      )}
      <Section title={translate('certs_and_awards')}>
        <div className={styles.flexRow}>
          <div className={styles.flexBox}>
            <ListBox
              items={awards.items}
              image={awards.image}
              imageAlt={awards.imageAlt}
            />
          </div>
          <div className={styles.flexBox}>
            <ListBox
              items={certificates.items}
              image={certificates.image}
              imageAlt={certificates.imageAlt}
            />
          </div>
        </div>
      </Section>
      <Section title={translate('membership')}>
        <div className={styles.partnersContainer}>
          <Image
            alt={'alt'}
            filename="logo-pzfd.png"
            imgStyle={{
              objectFit: 'contain',
            }}
          />
          <Image
            alt={'alt'}
            filename="plgbc-logo.png"
            imgStyle={{
              objectFit: 'contain',
            }}
          />
          <Image
            alt={'alt'}
            filename="logo-pzpb.png"
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        </div>
      </Section>
    </Layout>
  );
};

HomePage.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default HomePage;
